import React from 'react';
import '../styles/Breaker.css';

const Breaker = () => {
  return (
    <div className="breaker">
    </div>
  );
};

export default Breaker;
