import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import FaTimes for the 'X' icon
import logo from '../assets/chow.png';
import '../styles/Header.css';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

const Header = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const closeMenu = () => setIsOpen(false);

  return (
    <header className="header">
      <img src={logo} alt="ChowGo" className="header-logo" />
      <FaBars className={`hamburger ${isOpen ? 'hidden' : ''}`} onClick={() => setIsOpen(true)} />
      <FaTimes className={`close-icon ${!isOpen ? 'hidden' : ''}`} onClick={closeMenu} />
      <nav className={`navbar ${isOpen ? 'open' : ''}`}>
        <FaTimes className={`close-icon ${isOpen ? '' : 'hidden'}`} onClick={closeMenu} />
        <div className={`nav-items ${isOpen ? 'open' : 'hidden'}`}>
          <a href="#tokenomics" onClick={closeMenu}>{t('tokenomics')}</a>
          <a href="#roadmap" onClick={closeMenu}>{t('roadmap')}</a>
          <a href="https://spooky.fi/#/" onClick={closeMenu}>{t('tradeLink')}</a>
          <LanguageSelector />
        </div>
      </nav>
    </header>
  );
};

export default Header;
