import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: {
          "welcome": "Welcome to ChowGo!",
          "intro": "Join the fluffy revolution and be part of the coolest canine crypto community. Where every token is as unique as the blue tongue of a Chow Chow!",
          "mission": "Our mission: To outrun every dog on the block – even the Shiba Inu!",
          "joinButton": "Join the #BlueTongueClan",
          "disclaimer": "Disclaimer: $CHOW is a meme token for entertain.",
          "tokenomics": "Tokenomics",
          "roadmap": "Roadmap",
          "tradeLink": "Trade ↗",
          "milestone1h": "Development",
          "milestone1d": "Smart contracts, website, and initial business relationships",
          "milestone1t": "Mar 2024",
          "milestone2h": "Fair Launch",
          "milestone2d": "Official launch of ChowGo",
          "milestone2t": "Apr 2024",
          "milestone3h": "Community",
          "milestone3d": "Fluffy community begins #BlueTongueClan",
          "milestone3t": "May 2024",
          "milestone4h": "ChowGo DAO",
          "milestone4d": "Launch the ChowGo DAO and $BTC governance token",
          "milestone4t": "Jul 2024",
          "milestone5h": "CEX Listings",
          "milestone5d": "Chow lists on exchanges!",
          "milestone5t": "Aug 2024",
          "milestone6h": "New Blockchains",
          "milestone6d": "Chow goes to mooore blockchains!",
          "milestone6t": "Sep 2024",
          "trade": "Trade $CHOW",
          "tradeOn": "Trade on ",
          "100fair": "100% Fair Distribution",
          "tokenDistribution": "Token Distribution",
          "chowGoTokenomics": "ChowGo Tokenomics",
          "tokenomicsIntro": "Understanding the value and distribution of $CHOW!",
          "totalSupply": "Total Supply",
          "tokenSupplyAmount": "888 Billion $CHOW",
          "zeroTax": "Zero Tax Policy",
          "zeroTaxDesc": "No transaction fees! Trade $CHOW freely!",
          "utility": "Utility and Rewards",
          "soon": "Soon: ",
          "utilityDesc": "Stake, trade, and participate in the #BlueTongueClan for rewards!",
          "contractAddress": "Token address:",
          "addToken": "Add $CHOW to MetaMask",
          "addTokenFail": "Could not add token to MetaMask",
          "addTokenNoMeta": "MetaMask is not installed!",
          "addTokenAvax": "Please switch to the Fantom network in MetaMask."
        }
      },
      zh: {
        translation: {
          "welcome": "欢迎来到ChowGo！",
          "intro": "加入这场毛茸茸的革命，成为最酷的加密犬社区的一员。每一枚代币都像松狮的蓝舌头一样独一无二！",
          "mission": "我们的使命：超越街区上的每一只狗 —— 甚至是柴犬！",
          "joinButton": "加入#蓝舌帮",
          "disclaimer": "免责声明：$CHOW 是为了娱乐而存在的模因代币。",
          "tokenomics": "代币经济学",
          "roadmap": "发展路线",
          "tradeLink": "交易 ↗",
          "milestone1h": "发展",
          "milestone1d": "智能合约、网站以及初步商业关系",
          "milestone1t": "2024年3月",
          "milestone2h": "公平启动",
          "milestone2d": "ChowGo的官方启动",
          "milestone2t": "2024年4月",
          "milestone3h": "社区",
          "milestone3d": "毛茸茸的社区开始 #蓝舌族",
          "milestone3t": "2024年5月",
          "milestone4h": "ChowGo DAO",
          "milestone4d": "启动ChowGo DAO和$BTC治理代币",
          "milestone4t": "2024年7月",
          "milestone5h": "中心化交易所上市",
          "milestone5d": "Chow在交易所上市!",
          "milestone5t": "2024年8月",
          "milestone6h": "新区块链",
          "milestone6d": "Chow进入更多区块链！",
          "milestone6t": "2024年9月",
          "trade": "交易 $CHOW",
          "tradeOn": "在此交易 ",
          "100fair": "100%公平分配",
          "tokenDistribution": "代币分布",
          "chowGoTokenomics": "ChowGo代币经济",
          "tokenomicsIntro": "了解$CHOW的价值和分配！",
          "totalSupply": "总供应量",
          "tokenSupplyAmount": "8880亿$CHOW",
          "zeroTax": "零税政策",
          "zeroTaxDesc": "无交易费！自由交易$CHOW！",
          "utility": "实用性和奖励",
          "soon": "即将：",
          "utilityDesc": "质押、交易，并参与#蓝舌帮以获取奖励！",
          "contractAddress": "代币地址:",
          "addToken": "将$CHOW添加到MetaMask",
          "addTokenFail": "无法将代币添加到MetaMask",
          "addTokenNoMeta": "未安装MetaMask！",
          "addTokenAvax": "请在MetaMask中切换到Fantom网络。"
        }        
      }
    },
    fallbackLng: "en",
    detection: {
      order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    react: {
      useSuspense: false,
    },
  });

  export default i18n;
