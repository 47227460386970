import React from 'react';
import '../styles/TradeInterface.css';
import spookyLogo from '../assets/spooky.png';
import curveLogo from '../assets/curve.png';
import { useTranslation } from 'react-i18next';

const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};

const TradeInterface = () => {
  const { t } = useTranslation();
  const exchanges = [
    { 
      name: 'SpookySwap', 
      url: 'https://spooky.fi/#/',
      logo: spookyLogo
    },
    { 
      name: 'Curve', 
      url: 'https://curve.fi/#/ethereum/swap',
      logo: curveLogo
    },
  ];

  return (
    <div className="trade-interface">
      <h2>{t('trade')}</h2>
      <div className="exchanges">
        {exchanges.map((exchange, index) => (
          <div key={index} className="exchange" onClick={() => openInNewTab(exchange.url)}>
            <img src={exchange.logo} alt={`${exchange.name} Logo`} className="exchange-logo" />
            <p>{t('tradeOn')} {exchange.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TradeInterface;
