import React from 'react';
import { useTranslation } from 'react-i18next';
import usFlag from '../assets/us.png'; // Path to your US flag image
import chinaFlag from '../assets/zh.png'; // Path to your China flag image
import '../styles/LanguageSelector.css';


const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language-selector">
      {i18n.language !== 'en' && (
        <button onClick={() => changeLanguage('en')}>
          <img src={usFlag} alt="English" />
        </button>
      )}
      {i18n.language !== 'zh' && (
        <button onClick={() => changeLanguage('zh')}>
          <img src={chinaFlag} alt="Chinese" />
        </button>
      )}
    </div>
  );
};

export default LanguageSelector;
