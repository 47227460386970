import React from 'react';
import { ArcElement, Chart as ChartJS, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useInView } from 'react-intersection-observer';
import '../styles/TokenDistribution.css';
import { useTranslation } from 'react-i18next';

ChartJS.register(ArcElement, Legend);

const TokenDistribution = () => {
  const { t } = useTranslation();

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5, // Adjust as needed
  });

  const data = {
    labels: [t('100fair')],
    datasets: [
      {
        label: t('100fair'),
        data: [100],
        backgroundColor: ['#D98943'],
        borderColor: ['#260101'],
        borderWidth: 10,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: '#260101', 
          font: {
            size: 18 
          }
        }
      }
    },
    animation: {
      animateRotate: inView,
    },
  };

  return (
    <div className="token-distribution" ref={ref}>
      <h2>{t('tokenDistribution')}</h2>
      <div className="token-pie">
        <Pie data={data} options={options} />
      </div>
    </div>
  );
};

export default TokenDistribution;
