import React from 'react';
import { FaPlusCircle } from 'react-icons/fa'; // Import Plus Circle icon for the button
import '../styles/AddToMetaMaskButton.css';
import { useTranslation } from 'react-i18next';


const AddToMetaMaskButton = () => {
    const { t } = useTranslation();
    const handleAddToken = async () => {
        const tokenAddress = "TBD";
        const tokenSymbol = "CHOW";
        const tokenDecimals = 18;
        const tokenImage = "https://chowgocoin.pages.dev/chow.png";
        const fantomChainId = '0xfa'; 

        try {
        if (window.ethereum && window.ethereum.isMetaMask) {
            const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
            if (currentChainId === fantomChainId) {
                await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                    address: tokenAddress,
                    symbol: tokenSymbol,
                    decimals: tokenDecimals,
                    image: tokenImage,
                    },
                },
                });
            } else {
                alert(t('addTokenAvax'));
              }
        } else {
            alert(t('addTokenNoMeta'));
        }
        } catch (error) {
        console.error(t('addTokenFail'), error);
        }
  };

  return (
    <button onClick={handleAddToken} title={t('addToken')}>
      <FaPlusCircle /> {t('addToken')}
    </button>
  );
};

export default AddToMetaMaskButton;
