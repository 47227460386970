import React from 'react';
import '../styles/Tokenomics.css';
import { useTranslation } from 'react-i18next';


const Tokenomics = () => {
  const { t } = useTranslation();

  return (
    <div className="tokenomics" id="tokenomics">
      <h2>{t('chowGoTokenomics')}</h2>
      <p>{t('tokenomicsIntro')}</p>
      
      <div className="token-details">
        <div className="token-detail">
          <h3>{t('totalSupply')}</h3>
          <p>{t('tokenSupplyAmount')}</p>
        </div>
        <div className="token-detail">
          <h3>{t('zeroTax')}</h3>
          <p>{t('zeroTaxDesc')}</p>
        </div>
        <div className="token-detail">
          <h3>{t('utility')}</h3>
          <p><b>{t('soon')}</b>{t('utilityDesc')}</p>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
