import React from 'react';
import '../styles/MainContent.css';
import chowgoLogo from '../assets/chow.png'; // Ensure you have a fun logo image
import { useTranslation } from 'react-i18next';
import AddToMetaMaskButton from './AddToMetaMaskButton';


const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};

const MainContent = () => {
  const { t } = useTranslation();

  return (
    <div className="main-content">
      <img src={chowgoLogo} alt="ChowGo Logo" className="logo" />
      <h1>{t('welcome')}</h1>
      <p>{t('intro')}</p>
      <p>{t('mission')}</p>
      <button type="button" className="join-button" onClick={() => openInNewTab("https://spooky.fi/#/")}>{t('joinButton')}</button>
      <AddToMetaMaskButton />
    </div>
  );
};

export default MainContent;
