import React from 'react';
import '../styles/Footer.css';
import { useTranslation } from 'react-i18next';
import CopyToClipboardButton from './CopyToClipboardButton';


const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <p>{t('contractAddress')} <code>TBD</code> <CopyToClipboardButton textToCopy="TBD" /></p>
      <p>{t('disclaimer')}</p>
    </footer>
  );
};

export default Footer;
