import React from 'react';
import '../styles/Roadmap.css';
import { useTranslation } from 'react-i18next';

const Roadmap = () => {
  const { t } = useTranslation();

  const milestones = [
    { title: t('milestone1h'), description: t('milestone1d'), date: t('milestone1t'), status: 'completed' },
    { title: t('milestone2h'), description: t('milestone2d'), date: t('milestone2t'), status: 'todo' },
    { title: t('milestone3h'), description: t('milestone3d'), date: t('milestone3t'), status: 'todo' },
    { title: t('milestone4h'), description: t('milestone4d'), date: t('milestone4t'), status: 'todo' },
    { title: t('milestone5h'), description: t('milestone5d'), date: t('milestone5t'), status: 'todo' },
    { title: t('milestone6h'), description: t('milestone6d'), date: t('milestone6t'), status: 'todo' },
  ];

  return (
    <div className="roadmap" id="roadmap">
      <h2>Roadmap</h2>
      <div className="milestones">
        {milestones.map((milestone, index) => (
          <div key={index} className={`milestone ${milestone.status}`}>
            <h3>{milestone.title}</h3>
            <p>{milestone.description}</p>
            <span>{milestone.date}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Roadmap;
