import React, { useState } from 'react';
import { FaRegClipboard, FaCheck } from 'react-icons/fa'; // Import clipboard and check icons
import '../styles/CopyToClipboardButton.css';

const CopyToClipboardButton = ({ textToCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset icon after 2 seconds
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <button onClick={handleCopy} aria-label="Copy to clipboard">
      {isCopied ? <FaCheck /> : <FaRegClipboard />}
    </button>
  );
};

export default CopyToClipboardButton;
