import React from 'react';
import Header from './components/Header';
import MainContent from './components/MainContent';
import Tokenomics from './components/Tokenomics';
import TokenDistribution from './components/TokenDistribution';
import Roadmap from './components/Roadmap';
import TradeInterface from './components/TradeInterface';
import Breaker from './components/Breaker';
import Footer from './components/Footer';
import './App.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Assuming you export i18n from i18n.js or index.js

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <div className="App">
      <Header />
      <MainContent />
      <Tokenomics />
      <TokenDistribution />
      <Roadmap />
      <TradeInterface />
      <Breaker />
      <Footer />
    </div>
    </I18nextProvider>
  );
};

export default App;
